<template>
    <div class="steps-contact py-4">
        <div class="container">
            <div class="row">
                <div class="col-auto">
                    <img class="" :src="imgUrl" alt="Ansprechpartner">
                </div>
                <div class="col">
                    <p>
                        Ihr zuständiger Gebrauchtmaschinen-Experte {{ fullNameSalutation }} meldet sich bei Ihnen in den nächsten Tagen zu Ihrer Anfrage! Gerne können Sie aber auch jederzeit selbst Kontakt aufnehmen.
                    </p>
                    <steps-contact-address
                            class="d-none d-md-block"
                            :contact-data="contactData"
                            :full-name="fullName"></steps-contact-address>
                </div>
            </div>
            <div class="row mt-2 d-md-none" v-if="contactData.street && contactData.street !== ''">
                <div class="col">
                    <steps-contact-address
                            :contact-data="contactData"
                            :full-name="fullName"></steps-contact-address>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex';

    import contactPlaceholder from '../assets/images/platzhalter-contact.png';
    import StepsContactAddress from "./StepsContactAddress";

    export default {
        name: 'steps-contact',
        components: {StepsContactAddress},
        // created() {
        //     this.$store.commit('setContactData', {
        //         last_name_de: 'Mustermann',
        //         first_name_de: 'Max',
        //         salutation: 'Herr',
        //         expert_branch: 'Niederlassung Irgendwo',
        //         filePicUrl: '',
        //         street: 'Straße 3',
        //         zipCode: '81800',
        //         city: 'München',
        //         email: 'aaa@bbb.com',
        //         mobile: '2222222222',
        //         phone: '111111111',
        //         fax: 'fax423456467'
        //     });
        // },
        computed: {
            ...mapState({
                contactData: state => state.contactData
            }),
            fullName() {
                return this.contactData.first_name_de + ' ' + this.contactData.last_name_de;
            },
            fullNameSalutation() {
              return (this.contactData.salutation || '') + ' ' + this.fullName;
            },
            imgUrl() {
                if (this.contactData.filePicUrl && this.contactData.filePicUrl !== '') {
                    return this.contactData.filePicUrl;
                }
                return contactPlaceholder;
            }
        }
    }
</script>

<style lang="scss">
    .steps-contact {
        img {
            max-width: 85px !important;
            height: auto;
        }
    }
</style>